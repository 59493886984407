<template>
    <div class="row"><!-- :style="{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'contain' }"-->
        <div class="col-2"></div>
        <div class="col-8">
            <div>
                <p></p>
                <p>
                    <b>State of Being</b> emerged from Cleveland's alternative and industrial scene in the early 1990s, founded by singer-songwriter <b>Christopher Foldi</b>. The band gained early momentum after opening for <b>Chemlab</b> at the legendary <b>Phantasy Nite Club</b>, setting the stage for the release of their debut CD, <i>Dysfunctional Vision</i>, a unique fusion of synthpop, industrial, and punk.
                </p>
                <p>
                    State of Being's dynamic live shows led to performances alongside <b>Pigface</b>, <b>Thrill Kill Kult</b>, <b>The Used</b>, and <b>The Church</b>, along with side-stage gigs for icons like <b>The Ramones</b> and <b>Billy Idol</b>.
                </p>
                <p>
                    In 2004, the band released <i>Haywire</i>, produced by <b>Andy Kubiszewski</b> (formerly of <b>Stabbing Westward</b>), expanding their sound and blending rock, industrial, and electronic influences. The album gained international airplay, with <i>Skope Magazine</i> hailing it as “aggro-indie.”
                </p>
                <p>
                    Now in 2025, State of Being returns with a revitalized lineup, including <b>Chris Becka</b> on guitar, <b>Greg Boehnlein</b> (The Chromes) on bass, <b>Sean Furlong</b> (Prick) on drums, and Foldi’s brother, <b>Scott Foldi</b>, on keys. This refreshed lineup breathes new life into their classic material, blending fresh energy with the raw spirit of their early days.
                </p>
                <p>
                    <img :src="imageUrl" style="max-width: 100%; height: auto; ">
                </p>
            </div>
            <div class="col-2"></div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import bandImage from '@/assets/band-photo_lt-grey-crop-darker.jpg';

export default defineComponent({
    name: "BioText",
    data() {
    return {
      imageUrl: bandImage
    }
  }
});
</script>
